import "./SalmonSoftwareCategorySearchBanner.scss";

import React, { FunctionComponent } from "react";
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from "../../contentParts/mediaImage/PuxMediaSelector";
import PuxWysiwyg, { PuxWysiwygHtmlBodyType } from "../PuxWysiwyg/PuxWysiwyg";
import { useState } from "react";

export interface SalmonSoftwareCategorySearchBannerType {
  searchBannerHandler: (value: string) => void
  searchBannerDefaultInputValue?: string
  searchBannerImage: PuxMediaSelectorType;
  searchBannerContent: PuxWysiwygHtmlBodyType;
}

const SalmonSoftwareCategorySearchBanner: FunctionComponent<
  SalmonSoftwareCategorySearchBannerType
> = (props) => {
  const [inputValue, inputValueSet] = useState<string>(props.searchBannerDefaultInputValue ?? "")

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    inputValueSet(event.currentTarget.value ?? "")
  }

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    props.searchBannerHandler(inputValue)
  }

  return (
    <div className="CategorySearchBanner">
      <div className="CategorySearchBanner-image">
        <PuxMediaSelector {...props.searchBannerImage} />
      </div>
      <div className="PuxContainer">
        <div className="CategorySearchBanner-content">
          <PuxWysiwyg content={props.searchBannerContent} />
          <form onSubmit={(event) => onSubmitHandler(event)}>
            <input type="text" value={inputValue} onChange={onChangeHandler} placeholder="Search" />
            <button></button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SalmonSoftwareCategorySearchBanner;
